var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tPortfolioProject",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tPortfolioProjectForm",
          attrs: {
            model: _vm.tPortfolioProjectForm,
            rules: _vm.tPortfolioProjectFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "所属科室", prop: "officeId" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                            "label-in-value": "",
                          },
                          on: { "on-change": _vm.onSectionChange },
                          model: {
                            value: _vm.tPortfolioProjectForm.officeId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "officeId",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.officeId",
                          },
                        },
                        _vm._l(_vm.sectionList, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.id.toString() } },
                            [_vm._v(_vm._s(item.sectionName) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "名称", prop: "name" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入名称",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.tPortfolioProjectForm, "name", $$v)
                          },
                          expression: "tPortfolioProjectForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "简称", prop: "shortName" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入简称",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.shortName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tPortfolioProjectForm,
                              "shortName",
                              $$v
                            )
                          },
                          expression: "tPortfolioProjectForm.shortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "父节点", prop: "parentId" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.parentId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "parentId",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.parentId",
                          },
                        },
                        _vm._l(_vm.parentIdPriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "排序", prop: "orderNum" } },
                    [
                      _c("InputNumber", {
                        attrs: {
                          disabled: _vm.disabled,
                          placeholder: "请输入排序",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.orderNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.tPortfolioProjectForm, "orderNum", $$v)
                          },
                          expression: "tPortfolioProjectForm.orderNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "标本", prop: "specimen" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.specimen,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "specimen",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.specimen",
                          },
                        },
                        _vm._l(_vm.specimenPriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "销售价", prop: "salePrice" } },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            type: "number",
                            disabled: _vm.disabled,
                            placeholder: "请输入销售价",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.salePrice,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "salePrice",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.salePrice",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "append" }, slot: "append" },
                            [_vm._v("（元）")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "成本价", prop: "costPrice" } },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            type: "number",
                            disabled: _vm.disabled,
                            placeholder: "请输入成本价",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.costPrice,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "costPrice",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.costPrice",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "append" }, slot: "append" },
                            [_vm._v("（元）")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "检查地址", prop: "address" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入检查地址",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.tPortfolioProjectForm, "address", $$v)
                          },
                          expression: "tPortfolioProjectForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "诊断模板", prop: "templeate" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.template,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "template",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.template",
                          },
                        },
                        _vm._l(_vm.templatePriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "服务类型", prop: "serviceType" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.serviceType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "serviceType",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.serviceType",
                          },
                        },
                        _vm._l(_vm.serviceTypePriority, function (item, i) {
                          return _c(
                            "Option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "是否为附件", prop: "isFile" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.isFile,
                            callback: function ($$v) {
                              _vm.$set(_vm.tPortfolioProjectForm, "isFile", $$v)
                            },
                            expression: "tPortfolioProjectForm.isFile",
                          },
                        },
                        [
                          _c("Option", { attrs: { value: "否" } }, [
                            _vm._v("否"),
                          ]),
                          _c("Option", { attrs: { value: "是" } }, [
                            _vm._v("是"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c("FormItem", { attrs: { label: "lis标本" } }, [
                    _c(
                      "label",
                      [
                        _c(
                          "Select",
                          {
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "请选择",
                              clearable: "",
                              "label-in-value": "",
                            },
                            on: { "on-change": _vm.onLisTypeSectionChange },
                            model: {
                              value: _vm.tPortfolioProjectForm.lisSpecimen,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tPortfolioProjectForm,
                                  "lisSpecimen",
                                  $$v
                                )
                              },
                              expression: "tPortfolioProjectForm.lisSpecimen",
                            },
                          },
                          _vm._l(_vm.lisSpecimenPriority, function (item, i) {
                            return _c(
                              "Option",
                              { key: i, attrs: { value: item.value } },
                              [_vm._v(_vm._s(item.title) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "lis标本名字" } },
                    [
                      _c("Input", {
                        attrs: {
                          maxlength: 50,
                          readonly: "",
                          placeholder: "请输入检查地址",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.lisSpecimenName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tPortfolioProjectForm,
                              "lisSpecimenName",
                              $$v
                            )
                          },
                          expression: "tPortfolioProjectForm.lisSpecimenName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "lis对应Id " } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入检查地址",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.lisId,
                          callback: function ($$v) {
                            _vm.$set(_vm.tPortfolioProjectForm, "lisId", $$v)
                          },
                          expression: "tPortfolioProjectForm.lisId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "lis对应编码" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入检查地址",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.lisCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tPortfolioProjectForm, "lisCode", $$v)
                          },
                          expression: "tPortfolioProjectForm.lisCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "检查部位" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入检查部位",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.deptName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tPortfolioProjectForm, "deptName", $$v)
                          },
                          expression: "tPortfolioProjectForm.deptName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "样本类型" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.sampleId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "sampleId",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.sampleId",
                          },
                        },
                        _vm._l(_vm.sampleList, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "是否条码" } },
                    [
                      _c(
                        "Select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                            clearable: "",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.ifBarcode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "ifBarcode",
                                $$v
                              )
                            },
                            expression: "tPortfolioProjectForm.ifBarcode",
                          },
                        },
                        [
                          _c("Option", { attrs: { value: "否" } }, [
                            _vm._v("否"),
                          ]),
                          _c("Option", { attrs: { value: "是" } }, [
                            _vm._v("是"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "条码数量" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入检查部位",
                        },
                        model: {
                          value: _vm.tPortfolioProjectForm.barcodeNum,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tPortfolioProjectForm,
                              "barcodeNum",
                              $$v
                            )
                          },
                          expression: "tPortfolioProjectForm.barcodeNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "lis样本量" } },
                    [
                      _c(
                        "Input",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "text",
                            maxlength: 50,
                            placeholder: "请输入样本采集量 如：5、10",
                          },
                          model: {
                            value: _vm.tPortfolioProjectForm.specimenCapacity,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tPortfolioProjectForm,
                                "specimenCapacity",
                                $$v
                              )
                            },
                            expression:
                              "tPortfolioProjectForm.specimenCapacity",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "append" }, slot: "append" },
                            [_vm._v("ml")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.tPortfolioProjectForm.isFile == "是"
            ? _c(
                "Row",
                [
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "FormItem",
                        { attrs: { label: "附件地址", prop: "url" } },
                        [
                          _c("Input", {
                            attrs: {
                              disabled: _vm.disabled,
                              type: "text",
                              placeholder: "请输入附件地址",
                            },
                            model: {
                              value: _vm.tPortfolioProjectForm.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.tPortfolioProjectForm, "url", $$v)
                              },
                              expression: "tPortfolioProjectForm.url",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Row",
            [
              _c(
                "FormItem",
                { attrs: { "label-width": 0 } },
                [
                  _c(
                    "Button",
                    {
                      attrs: { type: "primary", icon: "md-add" },
                      on: { click: _vm.bindProject },
                    },
                    [_vm._v("绑定项目")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Table", {
            ref: "table",
            attrs: {
              loading: _vm.loading,
              border: "",
              columns: _vm.columns,
              sortable: "custom",
              data: _vm.projectTableData,
              transfer: "",
              "max-height": "350",
            },
            on: {
              "on-sort-change": _vm.changeSort,
              "on-selection-change": _vm.showSelect,
            },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function ({ row, index }) {
                  return [
                    _c(
                      "Button",
                      {
                        attrs: { type: "error", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteData(row, index)
                          },
                        },
                      },
                      [
                        _c("Icon", { attrs: { type: "md-trash" } }),
                        _vm._v(" 删除 "),
                      ],
                      1
                    ),
                    _c(
                      "Button",
                      {
                        attrs: { type: "info", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(row, index)
                          },
                        },
                      },
                      [
                        _c("Icon", { attrs: { type: "ios-create-outline" } }),
                        _vm._v(" 修改 "),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("chooseProject", {
        staticClass: "chooseProject",
        attrs: {
          drawerTitle: _vm.drawerTitle,
          TOfficeId: _vm.TOfficeId,
          checkedList: _vm.projectTableData,
        },
        on: { handleSearch: _vm.setProjectTableData },
        model: {
          value: _vm.drawerShow,
          callback: function ($$v) {
            _vm.drawerShow = $$v
          },
          expression: "drawerShow",
        },
      }),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("updateTBaseProject", {
            attrs: {
              modalTitle: _vm.title,
              TBaseProjectId: _vm.TPortfolioProjectIds,
            },
            on: { handleSearch: _vm.getTPortfolioProject },
            model: {
              value: _vm.updateShow,
              callback: function ($$v) {
                _vm.updateShow = $$v
              },
              expression: "updateShow",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }